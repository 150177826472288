import React, {useEffect,useState} from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

import Map from '../components/map/map';
import {parseMessages, readPbf} from '../utils/gtfs-rt-utils';
import config from '../config';

export default function MapPage() {
    /*storage*/
    const [vehPos, setVehPos] = useState([]);
    const getData= async ()=>{
	////console.log('getData() start...');
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    //TODO Make fields available via configuration!
            const address = `${config.API}gtfs-rt-file`;
            const res = await axios.get(address,
					{
					    responseType: 'arraybuffer'
					});

            if(res.data){
                //console.log('getRry() res available');
		const rry = readPbf(res.data);
		const rryLngth= rry.length;
                //console.log('getRry() rryLngh: ' + rryLngth);

		const rryEVP = new Array();
		let countEntityVehiclePositions = 0;
		rry.forEach(entity => {
		    const entityVehiclePosition = entity.vehicle;
		    if (entityVehiclePosition) {
			rryEVP.push(entityVehiclePosition);
			countEntityVehiclePositions++;
		    }
		});
                //console.log('getRry() countEntityVehiclePositions: ' + countEntityVehiclePositions);

		/*set state*/
		setVehPos((messages) => rryEVP);
	    }else{
                console.error('getRry() res NOT available');
	    }

        } catch (err) {
            console.error('err.message: ' + err.message);
        }
	////console.log('getData() done.');
    };

    useEffect(()=>{
	/*do not wait the interval when component loads the first time*/
	getData();

	/*refresh data periodically*/
	const intervalCall=setInterval(()=>{
	    getData();
	}, 5000);
	/*TODO adjust interval, make it available via config file*/
	return ()=>{
	    /*clean up*/
	    clearInterval(intervalCall);
	};
    },[]);

    if (vehPos.length < 0) {
    return (
	<>
	    <Map messages={vehPos}/>
	</>
    );
    } else {
	return (
	    <Alert variant={'secondary'} onClose={() => setShow(false)} dismissible>
		<Badge bg="secondary">VehiclePostion</Badge> entities loading...
            </Alert>

	);
    }
}
